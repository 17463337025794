import "../scss/main.scss";

//スクロールした際の動きを関数でまとめる
function PageTopAnime() {
  var scroll = $(window).scrollTop();
  if (scroll >= 200){//上から200pxスクロールしたら
    $('#page-top').removeClass('DownMove');//#page-topについているDownMoveというクラス名を除く
    $('#page-top').addClass('UpMove');//#page-topについているUpMoveというクラス名を付与
  }else{
    if($('#page-top').hasClass('UpMove')){//すでに#page-topにUpMoveというクラス名がついていたら
      $('#page-top').removeClass('UpMove');//UpMoveというクラス名を除き
      $('#page-top').addClass('DownMove');//DownMoveというクラス名を#page-topに付与
    }
  }
}

var mySwiper = new Swiper ('.js-swiper_latest', {
    // effect: "fade",
    slidesPerView: "auto",
    spaceBetween: 20,
    // loopedSlides: 1,
    // slidesPerView: 'auto',
    breakpoints: {
      1000: {
        slidesPerView: 4,
        // loopedSlides: 4,
      },
      768: {
        slidesPerView: 3,
        // loopedSlides: 4,
      }
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    loop: true,
    loopFillGroupWithBlank: true,
    // observer: true,
		// observeParents: true,
    // autoplay: 3000,
    // pagination: '.swiper-pagination',
    // paginationClickable: true,
    // nextButton: '.swiper-button-next',
    // prevButton: '.swiper-button-prev',
});

var myPick = new Swiper ('.js-swiper_pick', {
    // effect: "fade",
    slidesPerView: "auto",
    spaceBetween: 20,
    freeMode: true,
    watchSlidesProgress: true,
    // speed: 2000,
    // navigation: {
    //   nextEl: ".swiper-pick-button-next",
    //   prevEl: ".swiper-pick-button-prev"
    // },
    // loop: true,
    // navigation: {
    //   nextEl: ".swiper-button-next",
    //   prevEl: ".swiper-button-prev"
    // },
});

var myPickMain = new Swiper ('.js-swiper_pick_main', {
    effect: "fade",
    slidesPerView: "auto",
    loop: true,
    speed: 2000,
    loopFillGroupWithBlank: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    thumbs: {
      swiper: myPick,
    },
    navigation: {
      nextEl: ".swiper-pick-button-next",
      prevEl: ".swiper-pick-button-prev"
    },
    // loop: true,
    pagination: {
      el: ".swiper-pick-pagination",
      clickable: true,
    },
});


// TOP画像削除
$('.js-delete').on('click', function () {
  $('.top').remove();
});

// TOPのタブ切り替え
$('.js-media_select').on('click', function(e) {
  var target = $(e.currentTarget);
  var list = $('.js-media_select');
  var contentList = $('.js-media_content_list');
  $.each(list, function(i, x) {
    if(target.val() == $(x).val()) {
      $(x).prev().addClass('active');
    } else {
      $(x).prev().removeClass('active');
    }
  });
  $.each(contentList, function(i, x) {
    if(target.val() == $(x).attr('data-content')) {
      $(x).addClass('active_tab');
    } else {
      $(x).removeClass('active_tab');
    }
  });
});

$('.js-lineup_tab').on('click', function (e) {
  var target = $(e.currentTarget);
  var list = $('.lineup_list_check');
  var tablist = $('.js-lineup_tab');
  // target.attr('href').replace(/#/g, '');
  $.each(list, function(i, x) {
    if(target.attr('href').replace(/#/g, '') == $(x).attr('data-id')) {
      $(x).fadeIn().css('display','flex').addClass('lineup_list_check_active');
    } else {
      $(x).css('display','none').removeClass('lineup_list_check_active');
    }
  });

  $.each(tablist, function(i, x) {
    if(target.attr('href').replace(/#/g, '') == $(x).attr('href').replace(/#/g, '')) {
      $(x).parent().addClass('active_news');
    } else {
      $(x).parent().removeClass('active_news');
    }
  });
});

$('.open_btn').on('click', function (e) {
  var target = $(e.currentTarget);
  target.toggleClass('active_sp');
  $('.sp_nav').toggleClass('panelactive');
});

$('.sp_nav .circles').on('click', function (e) {
  var target = $(e.currentTarget);
  $('.open_btn').removeClass('active_sp');
  target.removeClass('panelactive');
});

$('.js-down').on('click', function(e) {
  var target = $(e.currentTarget);
  target.siblings('.down_list').find('ul').toggleClass('sub_list');
  target.toggleClass('active_arrow');
});

$('.js-logoYomiuriX').on('mouseover', function(e) {
  var target = $(e.currentTarget);
  target.attr('src', './images/logo_yomiuriX-solutions_on.png');
});

$('.js-logoYomiuriX').on('mouseout', function(e) {
  var target = $(e.currentTarget);
  target.attr('src', './images/logo_yomiuriX-solutions_head.png');
});

$(document).ready(function() {
  var text = $('.js-textTrim');
  var textBig = $('.js-textBigTrim');
  var textCount = 48;
  var textBigCount = 60;
  var clamp = "…";
  // 小さいボックスの文字数制限
  for(var i = 0; i < text.length; i++) {
    if (text[i].innerText.length > textCount) {
      var str = text[i].innerText;
      str = str.substr(0, (textCount - 1));
      text[i].innerText = str + clamp;
    }
  }

  for(var i = 0; i < textBig.length; i++) {
    if (textBig[i].innerText.length > textCount) {
      var str = textBig[i].innerText;
      str = str.substr(0, (textCount - 1));
      textBig[i].innerText = str + clamp;
    }
  }
});

$(window).scroll(function () {
  PageTopAnime();/*機能編  8-1-2 ページの指定の高さを超えたら下から出現の関数を呼ぶ*/
  //   fadeAnime();/* 印象編 4 最低限おぼえておきたい動き*/
  // delayScrollAnime();/* 印象編 4-12 順番に現れる（CSS x jQuery）関数を呼ぶ*/
});

// #で始まるa要素をクリックした場合に処理（"#"←ダブルクォーテンションで囲むのを忘れずに。忘れるとjQueryのバージョンによっては動かない。。）
$('.page_top').on('click', function(){
  // 移動先を0px調整する。0を30にすると30px下にずらすことができる。
  var adjust = 0;
  // スクロールの速度（ミリ秒）
  var speed = 400;
  // アンカーの値取得 リンク先（href）を取得して、hrefという変数に代入
  var href= $(this).attr("href");
  // 移動先を取得 リンク先(href）のidがある要素を探して、targetに代入
  var target = $(href == "#" || href == "" ? 'html' : href);
  // 移動先を調整 idの要素の位置をoffset()で取得して、positionに代入
  var position = target.offset().top + adjust;
  // スムーススクロール linear（等速） or swing（変速）
  $('body,html').animate({scrollTop:position}, speed, 'swing');
  return false;
});

$('.js-menu a[href^="#"]').on('click', function(e) {
  var target = $(e.currentTarget);
  var adjust = 0;
  var speed = 400;
  var href = target.attr("href");
  var targetLink = $(href == "#" || href == "" ? 'html' : href);
  var position = targetLink.offset().top + adjust;
  $('body,html').animate({scrollTop:position}, speed, 'swing');
  return false;
});

$('.yomiuri_link a[href^="#"]').on('click', function(e) {
  var target = $(e.currentTarget);
  var adjust = 0;
  var speed = 400;
  var href = target.attr("href");
  var targetLink = $(href == "#" || href == "" ? 'html' : href);
  var position = targetLink.offset().top + adjust;
  $('body,html').animate({scrollTop:position}, speed, 'swing');
  return false;
});

// $('.js-magnifying').on('click', function(e) {
//   // $('.js-search_box').toggleClass('search_text_box_open');
//   $('.js-search_box').toggleClass('slideAnimeRightLeft');
// });
// $('.js-magnifying').on('click', function() {
//   $('.js-search_box').removeClass('slideAnimeLeftRight').addClass('slideAnimeRightLeft');
// });
//
// $('.js-search_box').on('blur', function() {
//   $('.js-search_box').removeClass('slideAnimeRightLeft').addClass('slideAnimeLeftRight');
// });

// $('.js-magnifying').on('focusin', function() {
//   $('.js-search_box').removeClass('slideAnimeLeftRight').addClass('slideAnimeRightLeft');
// }).on('focusout', function() {
//   $('.js-search_box').removeClass('slideAnimeRightLeft').addClass('slideAnimeLeftRight');
// });
